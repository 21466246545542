<ejs-dialog
  id="call-log-modal"
  #Dialog
  isModal="true"
  width="80%"
  [visible]="true"
  [allowDragging]="true"
  [header]="title | translate"
  [showCloseIcon]="true"
  (beforeClose)="close($event)"
  (open)="onOpen($event)"
>
  <!-- ---------------------------------------------------------------------------------- -->
  <ng-container [ngSwitch]="callLogView">
    <!-- #region CALL LOG SEARCH VIEW -->
    <call-log-search-view
      *ngSwitchCase="'SearchCallLogView'"
      [callerPhone]="phoneNumber"
      [searchData]="searchData"
      (openCallLogReport)="swapCallLogView($event)"
    ></call-log-search-view>
    <!-- #endregion -->
    <!-- #region CALL LOG REPORT VIEW -->
    <call-log-report-view
      *ngSwitchCase="'ReportCallLogView'"
      [isEditMode]="isEditMode"
      (saveReportData)="saveReportData($event)"
      (cancelEvent)="cancelEvent($event);"
    ></call-log-report-view>
    <!-- #endregion -->
  </ng-container>
</ejs-dialog>
