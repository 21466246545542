<div class="verification-label">
  <div class="verification-label__label">
    <label class="verification-label__label-text" [for]="labelFor">
      {{ label }}
    </label>
  </div>
  <div class="verification-label__verification">
    <radio-validation-label
      [groupName]="labelFor"
      [isDisabled]="isDisabled"
      [valueSelected]="valueSelected"
      (valueChanged)="onSwitchChanged(labelFor, $event)"
    ></radio-validation-label>
  </div>
</div>
