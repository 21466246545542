import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  DetailRowService,
  EditService,
  GridModule,
  PagerModule,
  PageService,
  PdfExportService,
  ReorderService,
  ResizeService,
  SearchService,
  SortService,
  ToolbarService,
} from '@syncfusion/ej2-angular-grids';
import {
  CarouselModule,
  ContextMenuModule,
  TabModule,
  ToolbarModule,
  SidebarModule,
  TreeViewModule,
} from '@syncfusion/ej2-angular-navigations';
import {
  ButtonModule,
  CheckBoxModule,
  RadioButtonModule,
} from '@syncfusion/ej2-angular-buttons';
import {
  DropDownListAllModule,
  DropDownListModule,
  MultiSelectAllModule,
} from '@syncfusion/ej2-angular-dropdowns';
import {
  MaskedTextBoxModule,
  NumericTextBoxAllModule,
  NumericTextBoxModule,
  UploaderModule,
  TextBoxModule,
} from '@syncfusion/ej2-angular-inputs';
import { DialogModule, TooltipModule } from '@syncfusion/ej2-angular-popups';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import {
  ProgressButtonModule,
  DropDownButtonModule,
} from '@syncfusion/ej2-angular-splitbuttons';

import {
  HtmlEditorService,
  ImageService,
  LinkService,
  RichTextEditorModule,
  ToolbarService as ToolbarServiceRte,
} from '@syncfusion/ej2-angular-richtexteditor';
import {
  AccumulationChartAllModule,
  BarSeriesService,
  CategoryService,
  ChartAllModule,
  ChartAnnotationService,
  LineSeriesService,
  MultiLevelLabelService,
  RangeColumnSeriesService,
  StackingBarSeriesService,
} from '@syncfusion/ej2-angular-charts';
import {
  CircularGaugeAllModule,
  GaugeTooltipService,
} from '@syncfusion/ej2-angular-circulargauge';
import { ProgressBarModule } from '@syncfusion/ej2-angular-progressbar';
import { CommunicationCardComponent } from './components/communication-card/communication-card.component';
import { CustomersCommunicationComponent } from './components/customers-communication/customers-communication.component';
import { EditModalCommunicationComponent } from './components/customers-communication/edit-modal-communication/edit-modal-communication.component';
import { KycComponent } from './components/kyc/kyc.component';
import { ListItemWithStatusComponent } from './components/list-item-with-status/list-item-with-status.component';
import { FormCardWithStatusComponent } from './components/form-card-with-status/form-card-with-status.component';
import { SendRequestModalComponent } from './components/send-request-modal/send-request-modal.component';
import { VulnerabilityComponent } from './components/vulnerability/vulnerability.component';
import { VulnerabilityModalComponent } from './components/vulnerability/vulnerability-modal/vulnerability-modal.component';
import { ProcessDynamicFormComponent } from './components/process-dynamic-form/process-dynamic-form.component';
import { MapAccountValuePipe } from './pipes/map-account-value.pipe';
import { HorizonIconModule, HorizonDocIconModule } from '@horizon/core-icons';
import { HorizonSectionSubHeaderModule } from '@horizon/core-layouts';
import { HorizonComponentsDmCoreModule } from '@horizon/dm-core';
import { HorizonComponentsCoreModule } from '@horizon/core';
import {
  HorizonDropdownModule,
  HorizonMultiselectModule,
} from '@horizon/core-dropdowns';
import { HorizonSectionHeaderModule } from '@horizon/core-layouts';
import {
  HorizonDynamicEditModalModule,
  HorizonDynamicFormModule,
} from '@horizon/dm-forms';
import {
  HorizonButtonModule,
  HorizonConfirmationButtonsSetModule,
} from '@horizon/core-buttons';
import {
  HorizonTextInputModule,
  HorizonDatepickerModule,
  HorizonTextareaModule,
  HorizonSwitchInputModule,
  HorizonCheckboxInputModule,
  HorizonNumberInputModule,
} from '@horizon/core-inputs';
import { HorizonGenericGridModule } from '@horizon/core-grids';
import { HorizonNotificationMsgModule } from '@horizon/core-notifications';
import { KeyInfoCardsComponent } from './components/key-info-cards/key-info-cards.component';
import { KeyInfoCardComponent } from './components/key-info-cards/key-info-card/key-info-card.component';
import { SplineLinesChartComponent } from './components/spline-lines-chart/spline-lines-chart.component';
import { DataGridComponent } from './components/data-grid/data-grid.component';
import { FormatCellElementPipe } from './components/data-grid/format-cell-element.pipe';
import { DateCardsChartComponent } from './components/date-cards-chart/date-cards-chart.component';
import { DateCardComponent } from './components/date-cards-chart/date-card/date-card.component';
import { HorizonGenesysTelephonyComponent } from './components/horizon-genesys-telephony/horizon-genesys-telephony.component';
import { DataTableComponent } from './components/data-table/data-table.component';
import { GeniousChatComponent } from './components/genious-chat/genious-chat.component';
import { GetCellIconPipe } from './components/data-table/pipes/get-cell-icon.pipe';
import { GetInnerGridModalTitlePipe } from './pipes/get-inner-grid-modal-title.pipe';
import { GetInnerGridModalContextPipe } from './pipes/get-inner-grid-modal-context.pipe';
import { ChatFloatingScreenComponent } from './components/chat-floating-screen/chat-floating-screen.component';
import { HorizonGenesysCallLogComponent } from './components/horizon-genesys-call-log/horizon-genesys-call-log.component';
import { CallLogReportViewComponent } from './components/horizon-genesys-call-log/views/call-log-report-view/call-log-report-view.component';
import { CallSearchViewComponent } from './components/horizon-genesys-call-log/views/call-log-search-view/call-log-search-view.component';
import { VerificationLabelComponent } from './components/horizon-genesys-call-log/components/verification-label/verification-label.component';
import { AccordionSectionComponent } from './components/horizon-genesys-call-log/components/accordion-section/accordion-section.component';
import { NewHorizonDynamicGridModule } from '@horizon/dm-grids';
import { RadioValidationLabelComponent } from './components/horizon-genesys-call-log/components/radio-validation-label/radio-validation-label.component';
import { HorizonGenesysTemporalSubstituteComponent } from './components/horizon-genesys-temporal-substitute/horizon-genesys-temporal-substitute.component';

@NgModule({
  declarations: [
    CommunicationCardComponent,
    CustomersCommunicationComponent,
    EditModalCommunicationComponent,
    KycComponent,
    ListItemWithStatusComponent,
    FormCardWithStatusComponent,
    SendRequestModalComponent,
    VulnerabilityComponent,
    VulnerabilityModalComponent,
    ProcessDynamicFormComponent,
    MapAccountValuePipe,
    KeyInfoCardsComponent,
    KeyInfoCardComponent,
    SplineLinesChartComponent,
    DataGridComponent,
    FormatCellElementPipe,
    DateCardsChartComponent,
    DateCardComponent,
    HorizonGenesysTelephonyComponent,
    DataTableComponent,
    GeniousChatComponent,
    ChatFloatingScreenComponent,
    GetCellIconPipe,
    GetInnerGridModalTitlePipe,
    GetInnerGridModalContextPipe,
    HorizonGenesysCallLogComponent,
    CallLogReportViewComponent,
    CallSearchViewComponent,
    VerificationLabelComponent,
    AccordionSectionComponent,
    RadioValidationLabelComponent,
    HorizonGenesysTemporalSubstituteComponent,
  ],
  imports: [
    TranslateModule,
    CarouselModule,
    CommonModule,
    ReactiveFormsModule,
    GridModule,
    PagerModule,
    ButtonModule,
    RadioButtonModule,
    TabModule,
    ToolbarModule,
    SidebarModule,
    TreeViewModule,
    CheckBoxModule,
    MultiSelectAllModule,
    NumericTextBoxModule,
    NumericTextBoxAllModule,
    DialogModule,
    TooltipModule,
    DatePickerModule,
    DropDownListAllModule,
    FormsModule,
    MaskedTextBoxModule,
    DropDownListModule,
    UploaderModule,
    TextBoxModule,
    ProgressButtonModule,
    DropDownButtonModule,
    RichTextEditorModule,
    ChartAllModule,
    CircularGaugeAllModule,
    ContextMenuModule,
    ProgressBarModule,
    AccumulationChartAllModule,
    //Horizon Components Library
    HorizonIconModule,
    HorizonSectionSubHeaderModule,
    HorizonComponentsCoreModule,
    HorizonComponentsDmCoreModule,
    HorizonDropdownModule,
    HorizonDocIconModule,
    HorizonSectionHeaderModule,
    HorizonDynamicEditModalModule,
    HorizonDynamicFormModule,
    HorizonConfirmationButtonsSetModule,
    HorizonTextInputModule,
    HorizonDatepickerModule,
    HorizonTextareaModule,
    HorizonSwitchInputModule,
    HorizonGenericGridModule,
    HorizonNotificationMsgModule,
    HorizonButtonModule,
    HorizonMultiselectModule,
    HorizonCheckboxInputModule,
    NewHorizonDynamicGridModule,
    HorizonNumberInputModule,
  ],
  providers: [
    BarSeriesService,
    StackingBarSeriesService,
    CategoryService,
    PageService,
    SortService,
    PdfExportService,
    EditService,
    ToolbarService,
    SearchService,
    ReorderService,
    ResizeService,
    DetailRowService,
    TranslatePipe,
    ToolbarServiceRte,
    LinkService,
    ImageService,
    HtmlEditorService,
    GaugeTooltipService,
    DatePipe,
    FormatCellElementPipe,
    MultiLevelLabelService,
    LineSeriesService,
    ChartAnnotationService,
    RangeColumnSeriesService,
    GetInnerGridModalTitlePipe,
    GetInnerGridModalContextPipe,
  ],
  exports: [
    CommunicationCardComponent,
    CustomersCommunicationComponent,
    EditModalCommunicationComponent,
    KycComponent,
    ListItemWithStatusComponent,
    FormCardWithStatusComponent,
    SendRequestModalComponent,
    VulnerabilityComponent,
    VulnerabilityModalComponent,
    ProcessDynamicFormComponent,
    KeyInfoCardsComponent,
    KeyInfoCardComponent,
    SplineLinesChartComponent,
    DataGridComponent,
    DateCardsChartComponent,
    HorizonGenesysTelephonyComponent,
    DataTableComponent,
    GeniousChatComponent,
    ChatFloatingScreenComponent,
    GetInnerGridModalTitlePipe,
    GetInnerGridModalContextPipe,
    HorizonGenesysCallLogComponent,
    CallLogReportViewComponent,
    CallSearchViewComponent,
    VerificationLabelComponent,
    AccordionSectionComponent,
    HorizonGenesysTemporalSubstituteComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
