import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ChangeDetectorRef,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { map, Observable, of, Subject, filter, takeUntil } from 'rxjs';
import { globalSettings } from 'src/global-settings';
import { HorizonGenesysCallLogService } from '@shared/services/horizon-genesys-call-log.service';
import { ApiExceptionsHandlerService } from '@shared/services/api-exceptions-handler.service';
import { TranslateService } from '@ngx-translate/core';
import { ReportData } from '../../models';
import { UsersService } from '@horizon/core';

@Component({
  selector: 'call-log-report-view',
  templateUrl: './call-log-report-view.component.html',
  styleUrls: ['./call-log-report-view.component.scss'],
})
export class CallLogReportViewComponent implements OnInit, OnDestroy {
  @Input() isEditMode: boolean = false;
  @Output() cancelEvent = new EventEmitter<boolean>();
  @Output() saveReportData: EventEmitter<ReportData> =
    new EventEmitter<ReportData>();

  private categories: Array<any>;
  private readonly destroy$ = new Subject<void>();
  private generalFormData: any;
  private identificationFieldsVerified: Array<{
    name: string;
    value: 'Valid' | 'Invalid';
    from: 'Id' | 'Verification';
  }> = [];
  private idVPromptsData: any;
  private isCallSummaryEdited: boolean = false;
  private verificationFieldsVerified: Array<{
    name: string;
    value: 'Valid' | 'Invalid';
    from: 'Id' | 'Verification';
  }> = [];
  private verificationSpecialFieldToVerify: string = 'Password';
  private reportData: ReportData;

  public areFormsInitialized: boolean = false;
  public callClosureCode: string;
  public callClosureCodeSelected: boolean = false;
  public callClosureOptions = [];
  public callClosureOptionsValues = {
    text: 'caption',
    value: 'key',
  };
  public callSummary: string;
  public callSummaryEdited: string;
  public canUserEditCallSummary: boolean = false;
  public categorySelected: boolean = false;
  public categoryOptions = [];
  public categoryOptionsValues = {
    text: 'caption',
    value: 'key',
  };
  public currentLanguage: string;
  public generalForm: FormGroup;
  public generalFormIcon: { [key: string]: any } = {
    direction: '',
    id_v: '',
    vulnerability: 'None',
  };
  public readonly globalSettings: any = globalSettings;
  public identificationFieldsPassed: number = 0;
  public identificationForm: FormGroup;
  public identificationFormGroupFields: Array<any>;
  public isAgentCalling: boolean = false;
  public isCallSummaryPopulated: boolean = false;
  public linkedAccounts: Array<any> = [];
  public passWordValidated: boolean = false;
  public prompts: Array<any>;
  public successfulContactOptions = [
    { caption: 'Yes', key: 'Yes' },
    { caption: 'No', key: 'No' },
  ];
  public successfulContactOptionsValues = {
    text: 'caption',
    value: 'key',
  };
  public telephoneNote: string;
  public verificationFieldsPassed: number = 0;
  public verificationForm: FormGroup;
  public verificationFormGroupFields: Array<any>;

  constructor(
    private readonly apiExceptionsHandlerService: ApiExceptionsHandlerService,
    private readonly cdr: ChangeDetectorRef,
    private readonly formBuilder: FormBuilder,
    private readonly callLogService: HorizonGenesysCallLogService,
    private translate: TranslateService,
    private readonly userService: UsersService
  ) {}

  async ngOnInit(): Promise<void> {
    this.currentLanguage = this.translate.currentLang;
    this.getFormData();
    this.getReportData();
    if (!this.isEditMode) {
      this.canUserEditCallSummary = this.userService.checkUserPermission(
        'CALL_SUMMARY',
        'UPDATE'
      );
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }

  /**
   * The function `callClosureChanged` updates the `CallClosure` property in the
   * `reportData.CallLog` object based on the key of the selected item in the
   * event.
   * @param {any} event - The `event` parameter is an object that contains
   * information about the event that triggered the `callClosureChanged` function.
   * In this case, it seems to have an `itemData` property that contains the key
   * value that is being assigned to `this.reportData.CallLog.CallClosure`.
   */
  public callClosureChanged(event: any): void {
    this.callClosureCodeSelected = true;
    this.reportData.CallLog.CallClosure = event.itemData.key;
  }

  /**
   * The function `categoryChanged` updates `callClosureOptions` based on the
   * selected category key from the event.
   * @param {any} event - The `event` parameter in the `categoryChanged` method is
   * an object that represents the event triggered when a category selection is
   * changed. It likely contains information about the selected item, such as its
   * key or value.
   */
  public categoryChanged(event: any): void {
    this.reportData.Category = event.itemData.key;
    this.categorySelected = true;
    this.callClosureCodeSelected = false;
    this.callClosureOptions = this.populateCallClosureOptions(
      event.itemData.key
    );
  }

  public disableCancelButton(): boolean {
    return (
      !this.isEditMode ||
      (!this.isEditMode &&
        this.canUserEditCallSummary &&
        !this.isCallSummaryEdited)
    );
  }

  public disableSaveButton(): boolean {
    return (
      ((!this.isEditMode || !this.isCallSummaryPopulated) &&
        !this.canUserEditCallSummary) ||
      (!this.isEditMode &&
        this.canUserEditCallSummary &&
        !this.isCallSummaryEdited)
    );
  }

  /**
   * The function `getControl` retrieves a specific form control from a FormGroup
   * based on the field name.
   * @param {any} formGroup - The `formGroup` parameter is an object that
   * represents a FormGroup in Angular forms. It is used to group FormControls
   * together within a form.
   * @param {string} fieldName - The `fieldName` parameter in the `getControl`
   * function represents the name of the form control field that you want to
   * retrieve from the `formGroup`.
   * @returns The `getControl` function returns the AbstractControl associated with
   * the specified `fieldName` within the `formGroup`. If the `formGroup` is not an
   * instance of FormGroup or is null, it returns null.
   */
  public getControl(formGroup: any, fieldName: string): AbstractControl {
    if (formGroup && formGroup instanceof FormGroup) {
      return formGroup.get(`${fieldName}`);
    }
    return new FormControl('');
  }

  /**
   * The function `textAreaChange` updates specific fields in the `reportData`
   * object based on the provided field and event parameters.
   * @param {string} field - The `field` parameter is a string that specifies which
   * field in the form is being changed. It is used to determine which property of
   * the `reportData` object should be updated based on the event value.
   * @param {any} event - The `event` parameter in the `textAreaChange` function
   * likely refers to the event object that is triggered when the text area is
   * changed. This object contains information about the event, such as the target
   * element and the new value of the text area. It is commonly used in
   * event-driven programming to
   */
  public textAreaChange(field: string, event: any): void {
    if (this.isEditMode) {
      if (field === 'TelephoneNote') this.reportData.note.noteText = event;
      if (field === 'CallSummaryEdited')
        this.reportData.CallLog.CallSummaryEdited = event;
    } else if (field === 'CallSummaryEdited') {
      this.reportData.CallLog.CallSummaryEdited = event;
      if (this.generalFormData.rows[0].callSummaryEdited.value !== event)
        this.isCallSummaryEdited = true;
    }
  }

  public populateCallSummary(): void {
    //lorem ipsum
    this.callSummary =
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean vitae tincidunt quam, eget ullamcorper dui. In hac habitasse platea dictumst. Nulla vitae massa ullamcorper, facilisis metus non, feugiat metus. Cras leo lectus, lobortis eu justo in, porttitor aliquet felis. Donec a pretium lorem, vel iaculis nulla. Cras congue leo a metus molestie, vel sodales ante malesuada. Aenean et mi in justo tempor auctor. Praesent at sem lectus. Cras nisi ante, accumsan non dolor et, ultricies hendrerit diam.';
    this.reportData.CallLog.CallSummary = this.callSummary;
    this.isCallSummaryPopulated = true;
  }

  /**
   * The `saveData` function emits the `reportData` using the `saveReportData`
   * event.
   */
  public saveData(): void {
    if (this.canUserEditCallSummary && !this.isEditMode) {
      this.reportData.CallLog.Id = this.generalFormData.rows[0].id.value;
    }
    this.saveReportData.emit(this.reportData);
  }

  /**
   * The function `selectLinkedAccount` toggles the `Selected` property of a linked
   * account based on the provided `accountId`.
   * @param accountId - The `selectLinkedAccount` function takes an object as a
   * parameter with two properties: `accountId` of type number and `accountRef` of
   * type string. The function then searches for a linked account in the
   * `LinkedAccounts` array within `reportData.CallLog.AdditionalFields` based on
   * the
   */
  public selectLinkedAccount(accountId: {
    accountId: number;
    accountRef: string;
  }): void {
    const clickedLinkedAccount =
      this.reportData.CallLog.AdditionalFields.LinkedAccounts.findIndex(
        (linkedAccount) => linkedAccount.AccountId === accountId.accountId
      );
    if (clickedLinkedAccount !== -1)
      this.reportData.CallLog.AdditionalFields.LinkedAccounts[
        clickedLinkedAccount
      ].Selected =
        !this.reportData.CallLog.AdditionalFields.LinkedAccounts[
          clickedLinkedAccount
        ].Selected;
  }

  /**
   * The function successfulContactChanged updates the SuccessfulContact property
   * in the reportData.CallLog object based on the key of the itemData passed in
   * the event parameter.
   * @param {any} event - The `event` parameter in the `successfulContactChanged`
   * function is an object that contains information about the event that triggered
   * the function. It likely includes data related to the event that can be used to
   * determine the new successful contact value.
   */
  public successfulContactChanged(event: any): void {
    this.reportData.CallLog.SuccessfulContact =
      event.itemData.key === 'Yes' ? true : false;
  }

  /**
   * The function `getFormData` asynchronously retrieves form data from a service,
   * processes it, and initializes forms.
   */
  private async getFormData(): Promise<void> {
    await this.callLogService.formData$
      .pipe(
        filter((_res) => _res !== null),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (data) => {
          if (data?.General?.data && data?.ID_V_Prompt?.data) {
            this.generalFormData = data.General.data;
            this.idVPromptsData = data.ID_V_Prompt.data;

            this.initializeForms()
              .catch((error) => console.error(error))
              .finally(() => {
                this.areFormsInitialized = true;
              });
          }
        },
        error: (error) => {
          this.apiExceptionsHandlerService.defaultErrorToasts(
            error,
            'Call Log'
          );
        },
      });
  }

  /**
   * The function `getReportData` asynchronously retrieves report data from a
   * service and handles any errors that occur.
   */
  private async getReportData(): Promise<void> {
    await this.callLogService.reportData$
      .pipe(
        filter((_res) => _res !== null),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (data) => {
          this.reportData = data;
        },
        error: (error) => {
          this.apiExceptionsHandlerService.defaultErrorToasts(
            error,
            'Call Log'
          );
        },
      });
  }

  /**
   * The function `switchChanged` updates the `Result` property of a specific
   * content item in `reportData.CallLog.Content` based on the event and origin
   * form, and performs form verifications accordingly.
   * @param {any} event - The `event` parameter in the `switchChanged` function
   * seems to be an object that contains information about the event that triggered
   * the switch change. It likely includes properties like `name` and `value` that
   * are used to identify the specific switch that was changed and the new value of
   * the switch.
   * @param {string} originForm - The `originForm` parameter in the `switchChanged`
   * function represents the form type from which the event originated. It is used
   * to determine which specific form verification function to call based on the
   * type of form that triggered the event.
   */
  public switchChanged(event: any, originForm: string): void {
    const contentToModify = this.reportData.CallLog.Content.findIndex(
      (content) =>
        content.Type.toLowerCase() === originForm.toLowerCase() &&
        content.TextId === event.name
    );
    if (this.isEditMode)
      this.reportData.CallLog.Content[contentToModify].Result = event.value;

    const newFieldToVerify = {
      name: event.name,
      value: event.value,
      from: originForm,
    };
    this.idAndVFormsVerification(newFieldToVerify);

    if (
      this.identificationFieldsVerified.length >= 2 &&
      (this.verificationFieldsVerified.length >= 2 || this.passWordValidated)
    ) {
      this.generalForm.controls['id_v'].setValue(
        this.identificationFieldsPassed >= 2 &&
          (this.verificationFieldsPassed >= 2 || this.passWordValidated)
          ? 'Passed'
          : 'Failed'
      );
      this.reportData.CallLog.IDVStatus =
        this.generalForm.controls['id_v'].value;
    }
    this.setGeneralFormId_VIcon();
  }

  /**
   * The function `buildForm` creates a FormGroup based on input rows filtered by a
   * specific type, setting up FormControls with validators.
   * @param {any[]} rows - The `rows` parameter is an array containing objects that
   * represent form fields. Each object in the array has properties such as `Type`,
   * `FieldType`, `TextId`, and `Answer` which are used to build form controls in
   * the form group.
   * @param {string} type - The `type` parameter in the `buildForm` function is
   * used to filter the rows based on a specific type. The function filters the
   * rows array to include only those rows where the `Type` property matches the
   * provided `type` value. This allows you to build a form with form controls
   * based
   * @returns A FormGroup is being returned.
   */
  private buildForm(rows: any[], type: string): FormGroup {
    const group: { [key: string]: FormControl } = {};

    rows
      .filter(
        (row) =>
          row.type.value.toLowerCase() === type.toLowerCase() &&
          (row.answer?.value ?? '') !== ''
      )
      .forEach((row) => {
        const validators = this.getValidators(row.fieldType.extraData);

        group[row.textId.value] = new FormControl(
          row.fieldType.value === 'Date'
            ? new Date(row.answer.value)
            : row.answer.value || '',
          validators
        );
      });

    return this.formBuilder.group(group);
  }

  /**
   * The function `generateDynamicForm` creates dynamic forms for identification and
   * verification based on rows data.
   * @returns The `generateDynamicForm()` method returns an Observable that emits
   * void.
   */
  private generateDynamicForm(): Observable<void> {
    return of(this.idVPromptsData['rows']).pipe(
      map((rows: any[]) => {
        this.identificationForm = this.buildForm(rows, 'Id');
        this.verificationForm = this.buildForm(rows, 'Verification');
      })
    );
  }

  /**
   * The function `getValidators` generates an array of validators based on the
   * provided `extraData` object in TypeScript.
   * @param {any} extraData - The `extraData` parameter is an object that may
   * contain additional information or flags for validation purposes. It can
   * include properties such as `required` to indicate that a field is mandatory,
   * `email` to specify that the input should be a valid email address, and
   * potentially other properties for different types of
   * @returns An array of validators based on the `extraData` provided. The
   * validators added to the array depend on the properties of `extraData`, such as
   * `required` and `email`.
   */
  private getValidators(extraData: any): any[] {
    const validators = [];

    if (extraData) {
      if (extraData.required) {
        validators.push(Validators.required);
      }
      if (extraData.email) {
        validators.push(Validators.email);
      }
    }

    return validators;
  }

  /**
   * The function `idAndVFormsVerification` verifies and updates fields related to
   * identification and verification, tracking the number of fields that have
   * passed validation.
   * @param {any} newFieldToVerify - The `newFieldToVerify` parameter in the
   * `idAndVFormsVerification` function represents a new field that needs to be
   * verified. It contains information such as the field name, source
   * (identification or verification), and the value of the field (e.g., 'Valid' or
   * 'Invalid').
   */
  private idAndVFormsVerification(newFieldToVerify: any): void {
    const groupFieldToVerify =
      newFieldToVerify.from.toLowerCase() === 'id'
        ? 'identificationFieldsVerified'
        : 'verificationFieldsVerified';

    const index = this[groupFieldToVerify].findIndex(
      (field) =>
        field.name === newFieldToVerify.name &&
        field.from === newFieldToVerify.from
    );

    const fieldsPassed =
      newFieldToVerify.from.toLowerCase() === 'id'
        ? 'identificationFieldsPassed'
        : 'verificationFieldsPassed';

    if (index !== -1) {
      const pastValue = this[groupFieldToVerify][index].value;

      this[groupFieldToVerify][index].value = newFieldToVerify.value;

      if (newFieldToVerify.value === 'Valid' && pastValue !== 'Valid') {
        this[fieldsPassed] += 1;
      } else if (
        newFieldToVerify.value === 'Invalid' &&
        pastValue === 'Valid' &&
        this[fieldsPassed] > 0
      ) {
        this[fieldsPassed] -= 1;
      }
    } else {
      this[groupFieldToVerify].push(newFieldToVerify);

      if (newFieldToVerify.value === 'Valid') {
        this[fieldsPassed] += 1;
      }
    }

    this.verificationFieldsVerified.find((verifiedField) => {
      if (
        verifiedField.name.toLowerCase() ===
        this.verificationSpecialFieldToVerify.toLowerCase()
      ) {
        this.passWordValidated = verifiedField.value === 'Valid' ? true : false;
      }
    });
  }

  /**
   * The `initializeForms` function initializes dynamic forms for identification
   * and verification based on provided data.
   */
  private async initializeForms(): Promise<void> {
    this.initializeGeneralForm();
    this.identificationFormGroupFields = this.mapRowToFormData(
      this.idVPromptsData.rows,
      'Id'
    );
    this.verificationFormGroupFields = this.mapRowToFormData(
      this.idVPromptsData.rows,
      'Verification'
    );
    this.prompts = this.mapRowToFormData(this.idVPromptsData.rows, 'Prompt');

    await this.generateDynamicForm()
      .pipe(takeUntil(this.destroy$))
      .subscribe()
      .add(() => this.cdr.detectChanges());

    if (!this.isEditMode) {
      this.generalForm.disable({ onlySelf: true });
      this.identificationForm.disable({ onlySelf: true });
      this.verificationForm.disable({ onlySelf: true });
      this.telephoneNote = this.generalFormData.rows[0].callLogNote?.value;
      this.callSummary = this.generalFormData.rows[0].callSummaryEdited?.value;
    }
    this.cdr.detectChanges();
    this.areFormsInitialized = true;
  }

  /**
   * The function initializes a form with specific form controls and values based
   * on data from generalFormData.
   */
  private initializeGeneralForm(): void {
    this.populateCategoryOptions();
    if (this.isEditMode)
      this.isAgentCalling =
        this.callLogService.getSessionStorageData('GenesysData').callSubType ===
        'Agent';
    this.generalForm = new FormGroup({
      direction: new FormControl(
        this.isAgentCalling
          ? this.callLogService.getSessionStorageData('GenesysData').callType
          : this.generalFormData.rows[0].callType.value
      ),
      id_v: new FormControl(
        this.isEditMode
          ? 'Not Completed'
          : this.generalFormData.rows[0].idvStatus.value === 'Uncomplete'
          ? 'Not Completed'
          : this.generalFormData.rows[0].idvStatus.value
      ),
      vulnerability: new FormControl(
        this.generalFormData.rows[0].vulnerability.value
      ),
      category: new FormControl(this.generalFormData.rows[0].category.value),
      callFromTo: new FormControl(
        this.generalFormData.rows[0].callFromTo.value
      ),
      successfulContact: new FormControl(
        this.isAgentCalling
          ? ''
          : this.generalFormData.rows[0].successfulContact.value
          ? 'Yes'
          : 'No'
      ),
      accountNumber: new FormControl(
        this.generalFormData.rows[0].entityId.value.accountRef
      ),
      relationship: new FormControl(
        this.generalFormData.rows[0].relationship.value
      ),
      linkedAccount: new FormControl(
        this.generalFormData.rows[0].linkedAccounts.value
      ),
      callClosure: new FormControl(
        this.generalFormData.rows[0].callClosure?.value
      ),
    });
    this.linkedAccounts = this.generalFormData.rows[0].linkedAccounts.value;
    this.setGeneralFormIcons();
  }

  /**
   * The function `mapRowToFormData` filters rows based on a specified form type
   * and returns the filtered data.
   * @param {any} rows - The `rows` parameter is an array containing data that
   * needs to be filtered based on a specific form type.
   * @param {string} formType - The `formType` parameter in the `mapRowToFormData`
   * function is a string that specifies the type of form that you want to filter
   * the rows by. It is used to filter out rows that match the specified form type.
   * @returns An array of form data is being returned.
   */
  private mapRowToFormData(rows: any, formType: string): Array<any> {
    return rows.filter(
      (prompt: any) =>
        prompt.type.value.toLowerCase() === formType.toLowerCase()
    );
  }

  /**
   * The function `populateCallClosureOptions` populates an array of options based
   * on a given category number.
   * @param {number} categoryName - The `category` parameter in the
   * `populateCallClosureOptions` function is a number that represents the index of
   * a category in an array called `categories`.
   * @returns An array of objects with keys "key" and "caption" containing data
   * from the children of the category specified by the input parameter.
   */
  private populateCallClosureOptions(categoryName: string): Array<any> {
    if (categoryName !== '' && this.categories && this.categories.length > 0) {
      const filteredCategory = this.categories.find(
        (filteredCategory) => filteredCategory.name === categoryName
      );
      return filteredCategory.children.map((category) => {
        return {
          key: category.name,
          caption: category.displayName[this.currentLanguage.toLowerCase()],
        };
      });
    } else {
      return [];
    }
  }

  /**
   * The function `populateCategoryOptions` populates category options based on
   * data from `generalFormData`.
   */
  private populateCategoryOptions(): void {
    this.categories = this.generalFormData.columns.find(
      (column) => column.name === 'Category'
    ).options;
    this.callClosureOptions = this.populateCallClosureOptions(
      this.generalFormData.rows[0].category.value
    );
    this.categoryOptions = this.categories.map((category) => {
      return {
        key: category.name,
        caption: category.displayName[this.currentLanguage.toLowerCase()],
      };
    });
  }

  /**
   * Sets the icons for the general form based on form control values.
   *
   * @private
   * @description
   * This method handles the configuration of icons for different form controls:
   * - Direction icon: Sets 'callOut' or 'callIn' based on direction value
   * - Vulnerability icon: Sets icon properties based on vulnerability level (Low/Medium/High)
   *   - Low: green check
   *   - Medium: yellow warning
   *   - High: red cross
   *   - Default: grey warning
   *
   * @returns {void}
   *
   * @memberof CallLogReportViewComponent
   */
  private setGeneralFormIcons(): void {
    this.generalFormIcon.direction = {
      iconName:
        this.generalForm.controls['direction'].value === 'Outbound'
          ? 'callOut'
          : 'callIn',
      iconGroup: '',
      iconColor: globalSettings.colors.mainColor,
    };
    this.setGeneralFormId_VIcon();
    this.generalFormIcon.vulnerability = {
      iconName:
        this.generalForm.controls['vulnerability'].value === 'Low'
          ? 'tick_rounded'
          : this.generalForm.controls['vulnerability'].value === 'Medium'
          ? 'warning'
          : this.generalForm.controls['vulnerability'].value === 'High'
          ? 'cross_rounded'
          : 'warning',
      iconGroup:
        this.generalForm.controls['vulnerability'].value === 'Low'
          ? 'tooltip'
          : this.generalForm.controls['vulnerability'].value === 'Medium'
          ? ''
          : this.generalForm.controls['vulnerability'].value === 'High'
          ? ''
          : 'tooltip',
      iconColor:
        this.generalForm.controls['vulnerability'].value === 'Low'
          ? globalSettings.colors.successColor
          : this.generalForm.controls['vulnerability'].value === 'Medium'
          ? globalSettings.colors.warningColor
          : this.generalForm.controls['vulnerability'].value === 'High'
          ? globalSettings.colors.errorColor
          : globalSettings.colors.neutralDarkColor,
    };
  }

  /**
   * Sets the ID verification icon based on the form control value.
   *
   * @private
   * @description
   * Configures the ID verification icon properties based on three possible states:
   * - Not Completed: grey warning icon with tooltip
   * - Passed: green tick icon
   * - Failed: red cross icon
   *
   * @returns {void}
   *
   * @memberof CallLogReportViewComponent
   */
  private setGeneralFormId_VIcon(): void {
    this.generalFormIcon.id_v = {
      iconName:
        this.generalForm.controls['id_v'].value === 'Not Completed'
          ? 'warning'
          : this.generalForm.controls['id_v'].value === 'Passed'
          ? 'tick_rounded'
          : 'cross_rounded',
      iconGroup:
        this.generalForm.controls['id_v'].value === 'Not Completed'
          ? 'tooltip'
          : '',
      iconColor:
        this.generalForm.controls['id_v'].value === 'Not Completed'
          ? globalSettings.colors.neutralDarkColor
          : this.generalForm.controls['id_v'].value === 'Passed'
          ? globalSettings.colors.successColor
          : globalSettings.colors.errorColor,
    };
  }
}
