import { Injectable } from '@angular/core';
import { GridSettings } from '@horizon/core-grids';

@Injectable({
  providedIn: 'root',
})
export class CallLogGridConfigService {
  public gridSettings: GridSettings;
  public genericGridSettings: GridSettings;

  constructor() {
    this.setDefaultValues();
  }

  public setDefaultValues(): void {
    this.gridSettings = {
      headerSettings: {
        enableStickyHeader: true,
        showTooltip: false,
      },
      cellSettings: {
        allowTextWrap: true,
        textWrapSettings: { wrapMode: 'Content' },
      },
      toolbarSettings: {},
      allowReordering: false,
      allowResizing: true,
      allowSorting: true,
      allowMultiSorting: true,
      allowSelection: true,
      allowPdfExport: false,
      enableHover: true,
      allowPaging: false,
      pageSettings: { pageSizes: false, pageCount: 4, pageSize: 5 },
      editSettings: {
        allowEditing: false,
        allowAdding: false,
        allowDeleting: false,
      },
      allowFiltering: false,
      filterSettings: { type: 'Excel' },
      allowGrouping: false,
    };
    this.genericGridSettings = {
      headerSettings: {
        enableStickyHeader: true,
        showTooltip: false,
      },
      cellSettings: {
        allowTextWrap: true,
        textWrapSettings: { wrapMode: 'Content' },
      },
      toolbarSettings: {},
      allowReordering: false,
      allowResizing: true,
      allowSorting: true,
      allowMultiSorting: true,
      allowSelection: false,
      allowPdfExport: false,
      enableHover: true,
      allowPaging: false,
      pageSettings: { pageSizes: false, pageCount: 4, pageSize: 5 },
      editSettings: {
        allowEditing: false,
        allowAdding: false,
        allowDeleting: false,
      },
      allowFiltering: false,
      filterSettings: { type: 'Excel' },
      allowGrouping: false,
      sortOptions: {
        columns: [{ field: 'Date', direction: 'Descending' }],
      },
    };
  }
}
