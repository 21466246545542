import { LayoutItem } from '@horizon/dm-core';

export const layout: Array<LayoutItem> = [
  {
    id: 'customer-call-log-search-list-columns',
    elements: [
      {
        headerText: 'CustomerName',
        headerTextTranslations: {
          en: 'Customer Name',
          es: 'Nombre del Cliente',
        },
        field: 'CustomerName',
        type: 'text',
        textAlign: 'Left',
      },
      {
        headerText: 'AccountRef',
        headerTextTranslations: {
          en: 'Account Number',
          es: 'Número de Cuenta',
        },
        field: 'AccountRef',
        type: 'text',
        textAlign: 'Left',
      },
      {
        headerText: 'DateOfBirth',
        headerTextTranslations: {
          en: 'Date of Birth',
          es: 'Fecha de Nacimiento',
        },
        format: 'dd/MM/yyyy',
        field: 'DateOfBirth',
        type: 'date',
        textAlign: 'Left',
      },
      {
        headerText: 'Address',
        headerTextTranslations: {
          en: 'Property Address',
          es: 'Dirección de la propiedad',
        },
        field: 'Address',
        type: 'text',
        textAlign: 'Left',
      },
    ],
  },
  {
    id: 'results-call-log-search-list-columns',
    elements: [
      {
        headerText: 'PhoneNumber',
        headerTextTranslations: {
          en: 'Telephone Number',
          es: 'Número de teléfono',
        },
        field: 'PhoneNumber',
        type: 'text',
        textAlign: 'Left',
      },
      {
        headerText: 'CustomerName',
        headerTextTranslations: {
          en: 'Customer Name',
          es: 'Nombre del Cliente',
        },
        field: 'CustomerName',
        type: 'text',
        textAlign: 'Left',
      },
      {
        headerText: 'AccountRef',
        headerTextTranslations: {
          en: 'Account Number',
          es: 'Número de Cuenta',
        },
        field: 'AccountRef',
        type: 'text',
        textAlign: 'Left',
      },
      {
        headerText: 'DateOfBirth',
        headerTextTranslations: {
          en: 'Date of Birth',
          es: 'Fecha de Nacimiento',
        },
        field: 'DateOfBirth',
        format: 'dd/MM/yyyy',
        type: 'date',
        textAlign: 'Left',
      },
      {
        headerText: 'Address',
        headerTextTranslations: {
          en: 'Property Address',
          es: 'Dirección de la propiedad',
        },
        field: 'Address',
        type: 'text',
        textAlign: 'Left',
      },
      {
        headerText: 'Relationship',
        headerTextTranslations: {
          en: 'Customer Relationship',
          es: 'Relación del Cliente',
        },
        field: 'Relationship',
        type: 'text',
        textAlign: 'Left',
      },
    ],
  },
  {
    id: 'recent-calls-call-log-search-list-columns',
    elements: [
      {
        headerText: 'TelephoneNumber',
        headerTextTranslations: {
          en: 'Telephone Number',
          es: 'Número de teléfono',
        },
        field: 'TelephoneNumber',
        type: 'text',
        textAlign: 'Left',
      },
      {
        headerText: 'CustomerName',
        headerTextTranslations: {
          en: 'Customer Name',
          es: 'Nombre del Cliente',
        },
        field: 'CustomerName',
        type: 'text',
        textAlign: 'Left',
      },
      {
        headerText: 'AccountRef',
        headerTextTranslations: {
          en: 'Account Number',
          es: 'Número de Cuenta',
        },
        field: 'AccountRef',
        type: 'text',
        textAlign: 'Left',
      },
      {
        headerText: 'DateOfBirth',
        headerTextTranslations: {
          en: 'Date of Birth',
          es: 'Fecha de Nacimiento',
        },
        format: 'dd/MM/yyyy',
        field: 'DateOfBirth',
        type: 'date',
        textAlign: 'Left',
      },
      {
        headerText: 'PropertyAddress',
        headerTextTranslations: {
          en: 'Property Address',
          es: 'Dirección de la propiedad',
        },
        field: 'PropertyAddress',
        type: 'text',
        textAlign: 'Left',
      },
      {
        headerText: 'Date',
        headerTextTranslations: {
          en: 'Time - Date',
          es: 'Hora - Fecha',
        },
        format: 'HH:MM - dd/MM/yyyy',
        field: 'Date',
        type: 'date',
        textAlign: 'Right',
      },
    ],
  },
];
