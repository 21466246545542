import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastService } from '@horizon/core-notifications';
import { TranslateService } from '@ngx-translate/core';

interface ErrorResponse {
  severity: string;
  type: string;
  message: string;
  fields: { [key: string]: string };
}

interface Error {
  type: 'error' | 'warning';
  text: string;
}

@Injectable({
  providedIn: 'root',
})
export class ApiExceptionsHandlerService {
  constructor(
    private translate: TranslateService,
    private readonly toastService: ToastService
  ) {}

  public formatErrorMessage(
    response: ErrorResponse[],
    showGenericErrorIfNeeded: boolean = true
  ): any[] {
    if (response?.length) {
      return response.map((err: ErrorResponse) => {
        return {
          type: err.severity.toLowerCase(),
          text: this.translate.instant(
            `errors.${err.type}`,
            err.fields ? this.fieldsToLowerCase(err.fields) : {}
          ),
        };
      });
    } else {
      if (showGenericErrorIfNeeded) {
        return [{ text: 'errors.generic', type: 'error' }];
      } else {
        return [];
      }
    }
  }

  public defaultErrorToasts(response: HttpErrorResponse, api: string): void {
    switch (response.status) {
      case 400:
        const errors = this.formatErrorMessage(response.error);
        errors.map((err) => {
          this.toastService.showToastNotification({
            ...err,
            autoclose: false,
          });
        });
        break;
      case 404:
        this.toastService.showToastNotification({
          text: 'errors.generic',
          type: 'error',
          autoclose: false,
        });
        break;
      case 500:
        this.toastService.showToastNotification({
          text: this.translate.instant('errors.not_available', {
            api,
          }),
          type: 'error',
          autoclose: false,
        });
        break;
    }
  }

  public defaultErrorItems(response: HttpErrorResponse, api: string): Error[] {
    switch (response.status) {
      case 400:
        return this.formatErrorMessage(response.error);

      case 404:
        return [{ text: 'errors.generic', type: 'error' }];

      case 500:
        return [
          {
            text: this.translate.instant('errors.not_available', {
              api,
            }),
            type: 'error',
          },
        ];
    }
  }

  public fieldsToLowerCase(fields): Object {
    fields = Object.keys(fields).reduce((acc, key) => {
      const newKey = key.charAt(0).toLowerCase() + key.slice(1);
      acc[newKey] = fields[key];
      return acc;
    }, {} as any);

    return fields;
  }
}
