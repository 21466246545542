import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HorizonGenesysCallLogService } from '@shared/services/horizon-genesys-call-log.service';
import { globalSettings } from 'src/global-settings';

@Component({
  selector: 'horizon-genesys-temporal-substitute',
  templateUrl: './horizon-genesys-temporal-substitute.component.html',
  styleUrls: ['./horizon-genesys-temporal-substitute.component.scss'],
})
export class HorizonGenesysTemporalSubstituteComponent {
  @Output() public genesysSubstituteSearch = new EventEmitter<any>();
  @Output() public closeSubstitute = new EventEmitter<boolean>();
  private callId: string;
  public globalSettings: any = globalSettings;
  private phoneNumber: string;
  private callType: string;
  private callSubType: string;

  public callTypeCode: string;
  public isSubTypeCodeEnabled: boolean = false;

  public form: FormGroup;

  public callTypeOptions = [
    { key: 'Inbound', caption: 'Inbound' },
    { key: 'Outbound', caption: 'Outbound' },
  ];
  public callTypeOptionsValues = {
    text: 'caption',
    value: 'key',
  };

  public callSubTypeOptions = [
    { key: 'Dialler', caption: 'Dialler' },
    { key: 'Agent', caption: 'Agent' },
  ];
  public callSubTypeOptionsValues = {
    text: 'caption',
    value: 'key',
  };
  constructor(
    private readonly fb: FormBuilder,
    private readonly callLogService: HorizonGenesysCallLogService
  ) {
    this.form = this.fb.group({
      callId: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      callType: ['', Validators.required],
      callSubType: [''],
    });
  }

  public onBeforeClose(event: any): void {
    this.callLogService.setGenesysSubstituteIcon('default');
    this.closeSubstitute.emit(true);
  }

  public onCallIdChange(event: any): void {
    this.callId = event;
  }

  public onCallTypeChange(event: any): void {
    if (event.itemData.key === 'Outbound') {
      this.form.controls['callSubType'].setValidators(Validators.required);
      this.form.controls['callSubType'].setValue(undefined);

      this.isSubTypeCodeEnabled = true;
    }
    this.callType = event.itemData.key;
  }

  public onCallSubTypeChange(event: any): void {
    this.callSubType = event.itemData.key;
  }

  public onOpen(args: any): void {
    args.preventFocus = true;
  }

  public onPhoneNumberChange(event: any): void {
    this.phoneNumber = event;
  }

  public searchByTelephoneNumber(): void {
    this.callLogService.setSessionStorageData('GenesysData', {
      callID: this.formatCallId(),
      phoneNumber: this.phoneNumber,
      callType: this.callType,
      callSubType:
        (this.callSubType ?? '') !== '' ? this.callSubType : 'Dialler',
    });
    this.genesysSubstituteSearch.emit(true);
  }

  private formatCallId(): string {
    if (!this.callId || this.callId === '') return '';
    const partes = this.callId?.replace(/\/$/, '').split('/');
    return partes[partes.length - 1];
  }
}
