<!-- <form class="radio-validation-label" [formGroup]="form"> -->
<div class="e-section-control">
  <ul
    class="radio-validation-label__list"
    [ngClass]="isActive === true && 'radio-validation-label__list--active'"
  >
    <ng-container *ngFor="let option of radioValidationOptions">
      <li
        class="radio-validation-label__list-item"
        [ngClass]="{
          'radio-validation-label__radio-button--hidden':
            option.hidden === true,
          'radio-validation-label__radio-button--disabled': isDisabled
        }"
      >
        <ejs-radiobutton
          cssClass="radio-validation-label__radio-button"
          [ngClass]="{
            valid: option.value === 'Valid',
            invalid: option.value === 'Invalid'
          }"
          [label]="option.label"
          [checked]="option.checked"
          [disabled]="isDisabled"
          [name]="groupName"
          (change)="onChange(option)"
        ></ejs-radiobutton>
      </li>
    </ng-container>
  </ul>
</div>
