import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { HorizonGenesysCallLogService } from '../../services/horizon-genesys-call-log.service';
import { filter, Subject, takeUntil } from 'rxjs';
import { BeforeCloseEventArgs } from '@syncfusion/ej2-angular-popups';
import { ApiExceptionsHandlerService } from '@shared/services/api-exceptions-handler.service';
import { WorkloadApiService } from '@horizon/core';
import { NotificationService, ToastService } from '@horizon/core-notifications';
import { ReportDataVM } from './models/';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'horizon-genesys-call-log',
  templateUrl: './horizon-genesys-call-log.component.html',
  styleUrls: ['./horizon-genesys-call-log.component.scss'],
})
export class HorizonGenesysCallLogComponent implements OnInit, OnDestroy {
  @Input() activityId: number;
  @Input() callLogView: 'SearchCallLogView' | 'ReportCallLogView' =
    'SearchCallLogView';
  @Input() isEditMode: boolean = false;
  @Output() closeEvent = new EventEmitter<any>();
  @Output() genesysSubstituteSearch = new EventEmitter<any>();
  @Output() swapView = new EventEmitter<boolean>();

  public callId: string;
  public formData: any;
  public phoneNumber: string;
  public reportData: ReportDataVM = new ReportDataVM();
  public title: string;

  private callSubType: string;
  private callType: string;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly apiExceptionsHandlerService: ApiExceptionsHandlerService,
    private readonly callLogService: HorizonGenesysCallLogService,
    private readonly notificationService: NotificationService,
    private readonly toastService: ToastService,
    private readonly workloadApi: WorkloadApiService,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.callLogService.clearModalData();
    this.title = 'call_log.titles.call_log_search_view';
    if (this.callLogView === 'ReportCallLogView' && !this.isEditMode) {
      this.initializeViewCallLog();
      this.title = 'call_log.titles.call_log_report_view';
    }
  }

  ngOnDestroy(): void {
    this.callLogService.clearModalData();
    this.callLogService.deleteSessionStorageData('test');
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }

  public cancelEvent(event: boolean): void {
    if (event) {
      this.close();
      window.close();
    }
  }

  /**
   * Closes the component and emits a close event.
   * @param args - Optional close event arguments.
   */
  public close(args?: BeforeCloseEventArgs): void {
    this.closeEvent.emit(true);
  }

  /**
   * Handles the open event of the component.
   * @param args - The open event arguments.
   */
  public onOpen(args: any): void {
    args.preventFocus = true;
  }

  /**
   * Saves the report data by making an API call and handling the response.
   * @param $event - The event data containing the report information to be saved.
   */
  public saveReportData($event: any): void {
    this.workloadApi.saveCallLogActivity($event).subscribe({
      next: (response) => {
        this.notificationService.showLoadingSpinner();
        this.toastService.showToastNotification({
          text: 'Call Log saved',
          type: 'success',
        });
      },
      error: (errorResponse) => {
        this.apiExceptionsHandlerService.defaultErrorToasts(
          errorResponse,
          'Call Log'
        );
        this.notificationService.stopLoadingSpinner();
      },
      complete: () => {
        window.close();
        this.close();
        this.notificationService.stopLoadingSpinner();
      },
    });
  }

  /**
   * Swaps the call log view by fetching new data and updating the component state.
   * @param event - The event object containing details for the new view.
   */
  public async swapCallLogView(event: {
    isCallLogReportView: boolean;
    accountId: number;
    customerId: number;
    callType: string;
    callSubtype: string;
    relationship: string;
  }): Promise<void> {
    this.callType = event.callType;
    this.callSubType = event.callSubtype;

    await this.callLogService
      .getCallLogReport(
        event.customerId,
        event.accountId,
        event.callType,
        event.callSubtype,
        event.relationship
      )
      .pipe(
        filter((res) => res !== undefined),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (resp: any) => {
          this.notificationService.showLoadingSpinner();
          this.formData = { ...resp };
        },
        error: (errorResponse) => {
          this.apiExceptionsHandlerService.defaultErrorToasts(
            errorResponse,
            'Call Log'
          );
          this.notificationService.stopLoadingSpinner();
        },
        complete: () => {
          if (
            this.formData.General &&
            this.formData.General.data &&
            this.formData.ID_V_Prompt &&
            this.formData.ID_V_Prompt.data
          ) {
            this.swapCallLogViewCompleted();
            this.swapView.emit(event.isCallLogReportView);
            window.open(
              `/account/${event.accountId}/customers-overview`,
              '_blank'
            );
          }
          this.notificationService.stopLoadingSpinner();
        },
      });
  }

  /**
   * Builds the report data object based on the form data.
   */
  private buildReportData(): void {
    this.reportData.EntityId =
      this.formData['General'].data.rows[0].entityId.value.accountId;
    this.reportData.Category =
      this.formData['General'].data.rows[0].category.value;
    this.reportData.CallLog = {
      Id: this.formData['General'].data.rows[0].id?.value ?? -1,
      Type: this.callType,
      Subtype: this.callSubType,
      IDVStatus:
        this.formData['General'].data.rows[0].idvStatus?.value ?? 'Uncomplete',
      SuccessfulContact:
        this.formData['General'].data.rows[0].successfulContact.value,
      CustomerId: this.formData['General'].data.rows[0].customerId.value,
      LinkedAccounts: this.getLinkedAccountsIds(),
      AdditionalFields: {
        Vulnerability:
          this.formData['General'].data.rows[0].vulnerability.value,
        Relationship: this.formData['General'].data.rows[0].relationship.value,
        LinkedAccounts: this.pregenerateLinkedAccounts(),
      },
      Content: [],
      CallSummary: this.formData['General'].data.rows[0].callSummary
        ? this.formData['General'].data.rows[0].callSummary.value
        : '',
      CallSummaryEdited: this.formData['General'].data.rows[0].callSummaryEdited
        ? this.formData['General'].data.rows[0].callSummaryEdited.value
        : '',
      CallClosure: this.formData['General'].data.rows[0].callClosure.value,
      CallId: this.callId ? this.callId : '',
      PhoneNumber: this.phoneNumber ? this.phoneNumber : '',
    };
    this.reportData.SubEntityId =
      this.formData['General'].data.rows[0].customerId.value;
    this.callLogService.setReportData(this.reportData);
    this.pregenerateReportData().then((data) => {
      this.reportData.CallLog.Content = data;
      this.callLogService.setReportData(this.reportData);
    });
  }

  private getLinkedAccountsIds(): Array<number> {
    return this.formData['General'].data.rows[0].linkedAccounts.value.map(
      (linkedAccount) => linkedAccount.accountId ?? linkedAccount.AccountId
    );
  }

  /**
   * Initializes the view for a call log by fetching data and handling any errors.
   */
  private initializeViewCallLog(): void {
    this.callLogService
      .getCallLogView(this.activityId)
      .pipe(
        filter((res) => res !== undefined),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (response) => {
          this.formData = response;
          this.callLogService.setFormData(this.formData);
        },
        error: (errorResponse) => {
          this.apiExceptionsHandlerService.defaultErrorToasts(
            errorResponse,
            'Call Log'
          );
        },
        complete: () => {
          this.buildReportData();
        },
      });
  }

  private pregenerateLinkedAccounts(): Array<any> {
    const linkedAccounts =
      this.formData['General'].data.rows[0].linkedAccounts.value;
    return linkedAccounts.map((linkedAccount) => {
      return {
        AccountId: linkedAccount.accountId ?? linkedAccount.AccountId,
        AccountRef: linkedAccount.accountRef ?? linkedAccount.AccountRef,
        Selected: linkedAccount.selected ?? linkedAccount.Selected,
      };
    });
  }

  /**
   * Pre-generates report data by filtering and mapping rows based on specific criteria.
   * @returns An array of objects containing specific properties extracted from the formData.
   */
  private async waitForFormData(): Promise<void> {
    while (
      !this.formData ||
      !this.formData['ID_V_Prompt'] ||
      !this.formData['ID_V_Prompt'].data
    ) {
      await new Promise((resolve) => setTimeout(resolve, 50));
    }
  }

  private async pregenerateReportData(): Promise<Array<any>> {
    await this.waitForFormData();
    const reportData: Array<any> = this.formData['ID_V_Prompt'].data.rows
      .filter(
        (row) => row.type.value === 'ID' || row.type.value === 'Verification'
      )
      .map((row) => ({
        Type: row.type.value,
        FieldDataType: row.fieldType.value,
        TextId: row.textId.value,
        Answer: row.answer.value || '',
        Result: 'NA',
      }));

    return reportData;
  }

  /**
   * Completes the process of swapping the call log view.
   * Sets the title, form data, and builds the report data.
   */
  private swapCallLogViewCompleted(): void {
    this.title = 'call_log.titles.call_log_report_view';
    this.callLogService.setFormData(this.formData);
    this.callId =
      this.callLogService.getSessionStorageData('GenesysData').callID;
    this.phoneNumber =
      this.callLogService.getSessionStorageData('GenesysData').phoneNumber;
    this.buildReportData();
  }
}
