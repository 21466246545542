import { Component, Input } from '@angular/core';

@Component({
  selector: 'accordion-section',
  templateUrl: './accordion-section.component.html',
  styleUrls: ['./accordion-section.component.scss'],
})
export class AccordionSectionComponent {
  @Input() isOpen: boolean = false;
  constructor() {}

  public toggle(): void {
    this.isOpen = !this.isOpen;
  }
}
