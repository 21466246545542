import { Component, EventEmitter, Input, Output } from '@angular/core';
import { VerificationLabelEvent } from '../../models';

@Component({
  selector: 'verification-label',
  templateUrl: './verification-label.component.html',
  styleUrls: ['./verification-label.component.scss'],
})
export class VerificationLabelComponent {
  @Input() label: string;
  @Input() isRequired: boolean;
  @Input() isDisabled: boolean;
  @Input() isChecked: boolean;
  @Input() labelFor: string;
  @Input() valueSelected: string = 'NA';
  @Output() switchChanged = new EventEmitter<VerificationLabelEvent>();

  private eventToEmit: VerificationLabelEvent;
  constructor() {}

  public onSwitchChanged(field: string, event: any): void {
    this.eventToEmit = {
      name: field,
      value: event,
    };

    this.switchChanged.emit(this.eventToEmit);
  }
}
