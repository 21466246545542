import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';

@Component({
  selector: 'radio-validation-label',
  templateUrl: './radio-validation-label.component.html',
  styleUrls: ['./radio-validation-label.component.scss'],
})
export class RadioValidationLabelComponent implements OnChanges {
  @Input() activeColor: string = '#002841';
  @Input() groupName!: string;
  @Input() inactiveColor: string = '#9D9D9D';
  @Input() showBg: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() valueSelected: string = 'NA';

  @Output() valueChanged = new EventEmitter<any>();

  public isActive: boolean = false;
  public radioValidationOptions = [
    { label: '', value: 'Valid', checked: false },
    { label: '', value: 'NA', checked: false, hidden: true },
    { label: '', value: 'Invalid', checked: false },
  ];
  constructor() {}

  ngOnChanges(): void {
    this.radioValidationOptions.forEach((radioOption) => {
      if (radioOption.value === this.valueSelected) {
        radioOption.checked = true;
      } else {
        radioOption.checked = false;
      }
    });
  }

  public onChange(data: any) {
    this.isActive = true;
    this.valueChanged.emit(data.value);
  }
}
