import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlBuilder } from 'http-url-builder';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DashboardApiService {
  public baseUrl: UrlBuilder = new UrlBuilder(environment.apim.baseUrl)
    .addPath(environment.apim.apiSlugs.dashboardApi)
    .addPathVariable('/');

  constructor(private http: HttpClient) {}

  public getPersonasDashboard(id: number): Observable<string[]> {
    const fullUrl: string = this.baseUrl
      .addPath('Dashboard')
      .addPath('PersonasDashboard')
      .addPath(id?.toString())
      .build();
    return this.http.get<any>(fullUrl);
  }

  public getAccountDashboard(id: number): Observable<string[]> {
    const fullUrl: string = this.baseUrl
      .addPath('Dashboard')
      .addPath('AccountDashboard')
      .addPath(id?.toString())
      .build();
    return this.http.get<any>(fullUrl);
  }

  public getCustomerDashboard(id: number): Observable<string[]> {
    const fullUrl: string = this.baseUrl
      .addPath('Dashboard')
      .addPath('CustomerDashboard')
      .addPath(id?.toString())
      .build();
    return this.http.get<any>(fullUrl);
  }

  public getReport(name: string, id: number): Observable<string[]> {
    const fullUrl: string = this.baseUrl
      .addPath('Report')
      .addPath(name)
      .addPath(id?.toString())
      .build();
    return this.http.get<any>(fullUrl);
  }

  public getReportCallLog(id: number): Observable<string[]> {
    const fullUrl: string = this.baseUrl
      .addPath('Report')
      .addPath('CallLogActivity')
      .addPath('params?EntityId=' + id?.toString())
      .build();
    return this.http.get<any>(fullUrl);
  }

  public getCallLogReport(
    customerId: number,
    accountId: number
  ): Observable<string[]> {
    const fullUrl: string = this.baseUrl
      .addPath('Report')
      .addPath('RecentCallLogs')
      .addPath('params')
      .addQueryParam('CustomerId', customerId)
      .addQueryParam('AccountId', accountId)
      .build();
    return this.http.get<any>(fullUrl);
  }
}
