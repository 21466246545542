<div class="accordion-section">
  <div class="accordion-section__header">
    <div class="accordion-section__title">
      <ng-content select="[accordion-title]"></ng-content>
    </div>
    <div class="accordion-section__actions">
      <horizon-icon
        [ngClass]="
          isOpen
            ? 'accordion-section__actions--open'
            : 'accordion-section__actions--closed'
        "
        [iconName]="'down'"
        [iconGroup]="'carousel'"
        [size]="'18'"
        (click)="toggle()"
      ></horizon-icon>
    </div>
  </div>
  <div
    [ngClass]="
      isOpen
        ? 'accordion-section__content--open'
        : 'accordion-section__content--closed'
    "
    class="accordion-section__content"
  >
    <ng-content select="[accordion-content]"></ng-content>
  </div>
</div>
