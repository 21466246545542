import { ReportData, Content } from '..';
export class ReportDataVM implements ReportData {
  EntityId: number;
  Category: string;
  note: { noteText: string };
  entityType: string;
  CallLog: {
    Id: number;
    Type: string;
    Subtype: string;
    IDVStatus: string;
    SuccessfulContact: boolean;
    CustomerId: number;
    LinkedAccounts: Array<number>;
    AdditionalFields: {
      [key: string]: any;
    };
    Content: Array<Content>;
    CallSummary: string;
    CallSummaryEdited: string;
    CallClosure: string;
    CallId: string;
    PhoneNumber: string;
  };
  ScopeId: number;
  SubEntityId: number;
  constructor() {
    this.setValuesByDefault();
  }

  private setValuesByDefault(): void {
    this.EntityId = 0;
    this.Category = '';
    this.note = { noteText: ' ' };
    this.entityType = '';
    this.CallLog = {
      Id: -1,
      Type: '',
      Subtype: '',
      IDVStatus: '',
      SuccessfulContact: false,
      CustomerId: 0,
      LinkedAccounts: [],
      AdditionalFields: {
        Vulnerability: '',
        RelationShip: '',
        LinkedAccounts: [],
      },
      Content: [],
      CallSummary: '',
      CallSummaryEdited: '',
      CallClosure: '',
      CallId: '',
      PhoneNumber: '',
    };
    this.ScopeId = -1;
    this.SubEntityId = 0;
  }
}
